













import AddressAutocomplete from '@/components/AddressAutocomplete.vue';
import { View } from 'client-website-ts-library/plugins';
import { GeoSearchFeature } from 'client-website-ts-library/types/Geocoding/GeoSearchResult';
import { Component, Mixins } from 'vue-property-decorator';

import Form from '../components/Forms/Form.vue';

@Component({
  components: {
    Form,
    AddressAutocomplete,
  },
})
export default class SalesAppraisal extends Mixins(View) {
  private result: GeoSearchFeature | null = null;

  setSelection(result: GeoSearchFeature) {
    this.result = result;
  }
}
